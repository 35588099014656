import { createStore } from 'vuex'
import account from './account'
import aanmelding from './aanmelding'
import aanmeldOptions from './options'
import auth from './auth'
import vergaarbak from './vergaarbak'
import opmerkingen from './beheerOpmerkingen'

import createPersistedState from 'vuex-persistedstate'

export default createStore({
  modules: {
    familie: account,
    aanmelding,
    options: aanmeldOptions,
    auth,
    vergaarbak,
    opmerkingen: opmerkingen
  },
  plugins: [createPersistedState()]
})
