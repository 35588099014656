// familiegegevens
import Axios from 'axios'
import moment from 'moment'
import 'moment/locale/nl'

import voorkeursmomenten from './cached/voorkeursmomenten'

const getDefaultState = () => {
  return {
    voorkeursmomenten,
    aanmeldingen: [],
    aanmeldingenGrouped: [],
    currentAanmelding: {},
    packageVersion: process.env.PACKAGE_VERSION || '0',
    niveaus: {
      ski: [],
      snowboard: []
    },
    toonUnsetNiveaus: false,
    groupFamilies: false,
    sortColumn: {
      label: 'Datum',
      direction: 'asc'
    }
  }
}

const account = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getAanmelding ({ commit }, aanmeldingId) {
      return await Axios.get(process.env.VUE_APP_API_URL + 'aanmelding/' + aanmeldingId).then(response => {
        commit('setCurrentAanmelding', response.data)
        return response.data
      })
    },
    unsetCurrentAanmelding ({ commit }) {
      commit('setCurrentAanmelding', {})
    },
    async setNiveauDefinitief (state, niveau) {
      state.commit('setCurrentAanmeldingNiveauDefinitief', niveau)
      return await state.dispatch('checkForUserUpload').then(function (response) {
        const opmerking = {
          aanmeldingId: niveau.aanmeldingId,
          userId: state.rootState.auth.userId,
          modified: moment().format('YYYY-MM-DDTHH:mm:ss'),
          opmerking: 'Definitief niveau aangepast naar ' + niveau.niveauDefinitief,
          isWijziging: true
        }
        state.dispatch('opmerkingen/insert', opmerking, { root: true })
        return response
      })
    },
    async setNotitie (state, notitie) {
      state.commit('setCurrentAanmeldingNotitie', notitie)
      return await state.dispatch('checkForUserUpload').then(function (response) {
        const opmerking = {
          aanmeldingId: notitie.aanmeldingId,
          userId: state.rootState.auth.userId,
          modified: moment().format('YYYY-MM-DDTHH:mm:ss'),
          opmerking: 'Notitie aangepast',
          isWijziging: true
        }
        state.dispatch('opmerkingen/insert', opmerking, { root: true })
        return response
      })
    },
    async setNiveau (state, niveau) {
      state.commit('setCurrentAanmeldingNiveau', niveau)
      return await state.dispatch('checkForUserUpload').then(function (response) {
        const opmerking = {
          aanmeldingId: niveau.aanmeldingId,
          userId: state.rootState.auth.userId,
          modified: moment().format('YYYY-MM-DDTHH:mm:ss'),
          opmerking: 'Niveau aangepast naar ' + niveau.niveauId,
          isWijziging: true
        }
        state.dispatch('opmerkingen/insert', opmerking, { root: true })
        return response
      })
    },
    getNiveaus ({ commit }) {
      return Axios.get(process.env.VUE_APP_API_URL + 'vermoedelijkniveau')
        .then(response => {
          commit('setNiveaus', response.data)
          return response.data
        }
        ).catch(function (error) {
          return error
        })
    },

    getAanmeldingen ({ commit }, group) {
      return Axios.get(process.env.VUE_APP_API_URL + 'aanmeldingen' + (group ? '/' + group : ''))
        .then(response => {
          commit('setAanmeldingen', response.data)
          commit('groupAanmeldingen', response.data)
        }).catch(function (error) {
          return error
        })
    },
    reset ({ commit }) {
      commit('resetState')
    },
    async checkForUserUpload (state) {
      if (!state.state.currentAanmelding || !state.state.currentAanmelding.customer) {
        return false
      }
      return await state.dispatch('uploadUser', state.state.currentAanmelding.customer)
    },
    async uploadUser (state, customer) {
      const lid = customer.customerId
      return await Axios.put(process.env.VUE_APP_API_URL + 'Clients/' + lid, customer)
    }
  },
  mutations: {
    async setCurrentAanmeldingNiveau (state, niveau) {
      if (state.currentAanmelding.id !== niveau.aanmeldingId) {
        // not best practice, however, vergaarbak is stateless, so we need to get the aanmelding first
        await this.dispatch('account/getAanmelding', niveau.aanmeldingId)
      }
      if (niveau.discipline === 'ski') {
        state.currentAanmelding.customer.vermoedelijkNiveauSki = niveau.niveauId
      } else {
        state.currentAanmelding.customer.vermoedelijkNiveauSnowboard = niveau.niveauId
      }
    },
    async setCurrentAanmeldingNotitie (state, notitie) {
      if (state.currentAanmelding.id !== notitie.aanmeldingId) {
        // not best practice, however, vergaarbak is stateless, so we need to get the aanmelding first
        await this.dispatch('account/getAanmelding', notitie.aanmeldingId)
      }
      state.currentAanmelding.customer.remark = notitie.notitie
    },
    setCurrentAanmelding (state, aanmelding) {
      state.currentAanmelding = aanmelding
    },
    setCurrentAanmeldingNiveauDefinitief (state, niveau) {
      if (state.currentAanmelding.id !== niveau.aanmeldingId) {
        // not best practice, however, vergaarbak is stateless, so we need to get the aanmelding first
        this.dispatch('account/getAanmelding', niveau.aanmeldingId)
      }

      state.currentAanmelding.customer.currentLevel = niveau.niveauDefinitief
    },
    setNiveaus (state, niveaus) {
      // remove duplicates from niveaus where klassTypeIdGroep is the same
      niveaus = niveaus.filter(function (niveau, index, self) {
        return self.findIndex(function (n) {
          return n.klasTypeIdGroep === niveau.klasTypeIdGroep
        }) === index
      })
      // filter
      state.niveaus.snowboard = niveaus.filter(function (niveau) {
        return niveau.omschrijving === '2'
      }).sort(function (a, b) {
        return a.volgorde - b.volgorde
      })

      state.niveaus.ski = niveaus.filter(function (niveau) {
        return niveau.omschrijving === '1'
      }).sort(function (a, b) {
        return a.volgorde - b.volgorde
      })
    },
    setAanmeldingen (state, aanmeldingen) {
      state.aanmeldingen = aanmeldingen.map(function (a) {
        a.achternaam = (a.group.prelastname ? a.group.prelastname : '') + ' ' + a.group.lastname
        a.signupdate = a.signupdate.replace('T', ' ')

        const birthdate = new Date(a.customer.birthdate)
        const ageDifMs = Date.now() - birthdate.getTime()
        const ageDate = new Date(ageDifMs)
        a.leeftijd = Math.abs(ageDate.getUTCFullYear() - 1970)
        a.maanden = Math.floor(Math.abs(ageDate.getUTCMonth()))
        a.skiniveau = a.customer.vermoedelijkNiveauSki
        a.snowboardniveau = a.customer.vermoedelijkNiveauSnowboard
        a.voornaam = a.customer.firstname
        a.cursus_titel = a.cursus.titel

        // find all aanmeldingen with the same group.groupId
        // sort by signupdate
        // get the signupdate with the earlist date, set a.firstSignupdateInGroup
        var fullgroup = state.aanmeldingen.filter(function (aanmelding) {
          return aanmelding.group.groupId === a.group.groupId
        })
        if (fullgroup.length > 1) {
          a.firstSignupdateInGroup = fullgroup.reduce(function (prev, current) {
            return (prev.signupdate < current.signupdate) ? prev : current
          }
          ).signupdate
        }
        return a
      })
    },
    setGroupFamilies (state, val) {
      state.groupFamilies = val
    },
    setToonUnsetNiveaus (state, val) {
      state.toonUnsetNiveaus = val
    },
    setSortColumn (state, val) {
      state.sortColumn = val
    },
    groupAanmeldingen (state, collection) {
      var property = 'groupId'
      var i = 0
      var val
      var index
      var values = []
      var result = []
      for (i; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        collection[i].naam = collection[i].customer.firstname + ' ' + ((collection[i].group.prelastname ? collection[i].group.prelastname : '') + ' ' + collection[i].group.lastname).trim()
        if (index > -1) {
          result[index].children.push(collection[i])
        } else {
          values.push(val)
          result.push({
            groupId: val,
            consumer: {
              firstname: ''
            },
            achternaam: collection[i].achternaam,
            children: [collection[i]]
          })
        }
      }
      result.forEach(function (r) {
        // get the signupdate with the earlist date, convert to date from string first, dateInputFormat: 'yyyy-MM-dd H:m:s',
        r.signupdate = r.children.reduce(function (prev, current) {
          return (prev.signupdate < current.signupdate) ? prev : current
        }
        ).signupdate
        r.cursus = {
          titel: r.children[0].cursus.titel
        }
      })
      state.aanmeldingenGrouped = result
    },
    resetState (state) {
      // don't lose observers
      Object.assign(state, getDefaultState())
    }
  }
}

export default account
