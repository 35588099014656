import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import axios from 'axios'

import '@/assets/styles/theme.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

library.add(fat, fal, fad, far, fas)

axios.interceptors.request.use(
  config => {
    config.headers.Authorization = 'Bearer ' + store.state.auth.token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout')
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

axios.defaults.headers.common['Referrer-Policy'] = 'no-referrer'

createApp(App)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .mount('#app')
