<template>
    <div>
        <navBar></navBar>
        <router-view></router-view>
    </div>
  </template>

<script>
import navBar from '@/components/Nav.vue'
export default {
  components: {
    navBar
  },
  name: 'Home',
  methods: {
    uitloggen: function () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

  <style>
  .v--default-css .c-toast--success {
      background-color: var(--skipiste-color) !important
  }
  .bg-themed-light{
      min-height: 100vh;
      /* background: #f4eded; */
      /* background: #FEF9F6; */
      background: var(--background-light);
  }

  ul {
      list-style-type: none;
  }

  .navbar{
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
  }

  #dash-nav{
      /* margin-top: 61px; */
      padding-top: 12px;
      /* border-right: 1px solid #e0e0e0; */
      /* background: #FEF9F6; */
      background: var(--background-light);
  }

  #dash-content{
      padding-right: var(--bs-gutter-x,.75rem);
      padding-left: var(--bs-gutter-x,.75rem);
      background: #fff;
      min-height: 100vh;
  }

  p{
      color: #6a6a6a;
      font-family: "Open Sans",sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
  }

  .btn{
      text-decoration: none !important;
      border-radius: 10px !important;
      border: none !important;
      /* text-transform: uppercase !important; */

  }

  .sk-card{
    height: 300px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    background: #f4eded;
    margin-bottom: 10px;
  }

  .sk-card2{
    height: 250px;
  }

  .navbar-toggler{
    display: none;
  }

  .accent{
    color: var(--vibrant-accent);
  }

  @media  (max-width: 768px){
    .v--default-css .c-toast {
      margin: 0.5em !important;
    }
      .navbar-toggler{
        display: block;
      }
      p{
          color: #6a6a6a;
          font-family: "Open Sans",sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.2;
          letter-spacing: 1px;
      }
      .navbar{
          position: relative;
      }
      #dash-content{
          padding-right: calc(var(--bs-gutter-x) * .5);
          padding-left: calc(var(--bs-gutter-x) * .5);
      }
      #dash-nav{
          padding-top: 0;
          margin-top: 0;
      }
      #sidenav{
        padding-top: 12px;
      }
  }

  #dash-nav .list-group-item{
      border-radius: 10px;
      margin-bottom: 0.5rem;
      transition: all 50ms ease-in-out;
      border: none;
      font-weight: 500;
      letter-spacing: .1px;
      background: none;
  }

  #dash-nav .list-group-item svg{
    transform: all 50ms ease-in-out;
  }

  #dash-nav .list-group-item:hover{
      color: var(--skipiste-color);
  }
  #dash-nav .list-group-item.router-link-exact-active{
    color: var(--skipiste-color) !important;
  }

  #dash-nav .list-group-item.router-link-exact-active svg{
    color:#fff;
    margin-right: 10px;
  }

  #dash-nav .list-group-item.router-link-exact-active::before{
      content: "";
      position: absolute;
      top: 0;
      left: -56px;
      width: 100px;
      z-index: -1;
      height: 100%;
      background: var(--skipiste-color);
      border-radius: 10px;
      transition: all 50ms ease-in-out;
  }

  </style>
