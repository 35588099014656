import aanmeldingRouter from './aanmelding'
import authRouter from './auth'
import beheerRouter from './beheer'

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeElaborated.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Landing',
        component: () => import('../views/overzicht/LandingElaborated.vue')
      },
      {
        path: '/account',
        props: true,
        name: 'Account',
        component: () => import('../views/account/Account.vue')
      },
      {
        path: 'account/persoon/:id',
        name: 'PersoonBewerken',
        props: (route) => {
          const id = Number.parseInt(route.params.id, 10)
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
        },
        component: () => import('../views/account/PersoonBewerken.vue')
      }
    ]
  },
  {
    path: '/aanmelden',
    name: 'AanmeldingRoute',
    meta: { requiresAuth: true },
    component: () => import('../views/aanmelding/Aanmelding.vue'),
    children: aanmeldingRouter
  },
  {
    path: '/a',
    name: 'Auth',
    component: () => import('../views/auth/Container.vue'),
    children: authRouter
  },
  {
    path: '/beheer',
    name: 'beheer',
    meta: { requiresAuth: true },
    component: () => import('../views/beheer/Container.vue'),
    children: beheerRouter
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const APPENDIX = 'Skipiste Nieuwegein'
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - ${APPENDIX}` : APPENDIX
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
