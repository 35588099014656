import Axios from 'axios'
import moment from 'moment'
import 'moment/locale/nl'

var _ = require('lodash')

const PUT = 0
const POST = 1

const getDefaultState = () => {
  return {
    id: null,
    opmerking: '',
    activiteit: null,
    cursus: null,
    client: null,
    voorkeur: [{}, {}],
    materiaal: null,
    skikaart: null,
    ervaring: {
      eerder: null,
      afgelopenSeizoen: null,
      aantalWeken: 0,
      laatsteJaar: null,
      pistes: [],
      technieken: [],
      disciplines: []
    },
    akkoord: false,
    actief: false
  }
}

const aanmelding = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async accepted (state) {
      const complete = await state.dispatch('checkComplete')
      if (complete.status === false) {
        return complete
      } else {
        state.commit('setActief', true)
        await state.dispatch('uploadAanmelding', PUT)
        await state.dispatch('setStatusOk')
        await state.commit('setAanmelding', getDefaultState())
        return {
          status: true,
          stap: 'Landing'
        }
      }
    },
    checkComplete (state) {
      state.state.errorMessage = ''
      if (state.state.client === null) {
        return {
          status: false,
          stap: 'StapPersoon',
          message: 'Geen client geselecteerd.'
        }
      }
      if (state.state.activiteit === null) {
        return {
          status: false,
          stap: 'StapActiviteit',
          message: 'Geen activiteit geselecteerd.'
        }
      }
      if (state.state.cursus === null) {
        return {
          status: false,
          stap: 'StapCursus',
          message: 'Geen cursus geselecteerd.'
        }
      }
      if (state.state.voorkeur === null || state.state.voorkeur.length === 0 || state.state.voorkeur[0] === null || state.state.voorkeur[1] === null) {
        return {
          status: false,
          stap: 'StapMomenten',
          message: 'Selecteer twee voorkeursmomenten.'
        }
      }
      if (state.state.ervaring.eerder === null) {
        return {
          status: false,
          stap: 'StapErvaring',
          message: 'Geen ervaring geselecteerd.'
        }
      }
      if (!state.state.materiaal) {
        return {
          status: false,
          stap: 'StapMateriaal',
          message: 'Geen materiaal geselecteerd.'
        }
      }
      if (!state.state.skikaart) {
        return {
          status: false,
          stap: 'StapSkikaart',
          message: 'Geen skikaart geselecteerd.'
        }
      }
      if (state.state.akkoord === false) {
        return {
          status: false,
          stap: 'StapControle',
          message: 'Geen akkoord geselecteerd.'
        }
      }
      return {
        status: true,
        stap: 'Landing',
        message: ''
      }
    },
    setActiviteit (state, activiteit) {
      state.commit('setActiviteit', activiteit)

      state.commit('setCursus', getDefaultState().cursus)
      state.commit('setVoorkeur', getDefaultState().voorkeur)
      state.dispatch('resetErvaring')
      state.commit('setMateriaal', getDefaultState().materiaal)
      state.commit('setSkikaart', getDefaultState().skikaart)
      state.commit('setAkkoord', getDefaultState().akkoord)

      state.dispatch('checkForUpload')
    },
    setCursus (state, cursus) {
      state.commit('setCursus', cursus)

      state.commit('setVoorkeur', getDefaultState().voorkeur)

      state.commit('setAkkoord', getDefaultState().akkoord)

      if (cursus.isPriveles) {
        state.commit('setMateriaal', { id: 2, titel: 'Niet van toepassing' })
        state.commit('setSkikaart', { id: 1, skikaart1: 'Niet van toepassing' })
      } else {
        state.commit('setMateriaal', null)
        state.commit('setSkikaart', null)
      }
      state.dispatch('checkForUpload')
    },
    setOpmerking (state, opmerking) {
      state.commit('setOpmerking', opmerking)
      state.dispatch('checkForUpload')
    },
    setClient (state, client) {
      state.commit('setClient', client)
      localStorage.setItem('client', JSON.stringify(client))

      state.commit('setActiviteit', getDefaultState().activiteit)
      state.commit('setCursus', getDefaultState().cursus)
      state.commit('setVoorkeur', getDefaultState().voorkeur)
      state.dispatch('resetErvaring')
      state.commit('setMateriaal', getDefaultState().materiaal)
      state.commit('setSkikaart', getDefaultState().skikaart)
      state.commit('setAkkoord', getDefaultState().akkoord)

      state.dispatch('checkForUpload')
    },
    setMoment (state, payload) { // deprecated
      state.commit('setMoment', payload)
    },
    setDeel (state, p) { // deprecated
      state.commit('setDeel', p)
    },
    setVoorkeur (state, voorkeur) {
      state.commit('setVoorkeur', voorkeur)
      state.dispatch('checkForUpload')
    },
    resetErvaring (state) {
      state.commit('setErvaring', {
        key: 'eerder',
        value: getDefaultState().ervaring.eerder
      })
      state.commit('setErvaring', {
        key: 'afgelopenSeizoen',
        value: getDefaultState().ervaring.afgelopenSeizoen
      })
      state.commit('setErvaring', {
        key: 'aantalWeken',
        value: getDefaultState().ervaring.aantalWeken
      })
      state.commit('setErvaring', {
        key: 'laatsteJaar',
        value: getDefaultState().ervaring.laatsteJaar
      })
      state.commit('setErvaring', {
        key: 'pistes',
        value: getDefaultState().ervaring.pistes
      })
      state.commit('setErvaring', {
        key: 'technieken',
        value: getDefaultState().ervaring.technieken
      })
      state.commit('setErvaring', {
        key: 'disciplines',
        value: getDefaultState().ervaring.disciplines
      })
    },
    setErvaring (state, eerder) {
      state.commit('setErvaring', eerder)
      state.dispatch('uploadErvaring', eerder)
    },
    setMateriaal (state, materiaal) {
      state.commit('setMateriaal', materiaal)
      state.dispatch('checkForUpload')
    },
    setSkikaart (state, skikaart) {
      state.commit('setSkikaart', skikaart)
      state.dispatch('checkForUpload')
    },
    // TODO: populate correct state values
    getStored (state) {
      const aanmelding = localStorage.getItem('aanmelding')
      if (aanmelding) {
        state.commit('setAanmelding', JSON.parse(aanmelding))
      }
    },
    setAkkoord (state, akkoord) {
      state.commit('setAkkoord', akkoord)
    },
    setStatusOk (state) {
      Axios.post(process.env.VUE_APP_API_URL + 'aanmeldingaanmeldingstatustypes/', {
        aanmeldingId: state.state.id,
        aanmeldingStatusTypeId: 1,
        time: moment().format('YYYY-MM-DDTHH:mm:ss')
      })
        .then(response => {
          // set status 1 in store, however, gets overwritten in state reset
          return true
        })
    },
    reset ({ commit }) {
      commit('resetState')
    },
    uploadErvaring (state, e) {
      if (state.state.id === null) {
        return
      }
      switch (e.key) {
        case 'technieken':
          state.rootState.options.technieken.forEach(el => {
            Axios.put(process.env.VUE_APP_API_URL + 'aanmeldingtechniek1/' + state.state.id,
              {
                aanmeldingId: state.state.id,
                selected: state.state.ervaring.technieken.includes(el.id),
                techniekId: el.id
              }
            )
              .then(response => {
                // console.log(response)
              })
          })
          break
        case 'pistes':
          state.rootState.options.pistes.forEach(el => {
            Axios.put(process.env.VUE_APP_API_URL + 'aanmeldingpiste/' + state.state.id,
              {
                aanmeldingId: state.state.id,
                selected: state.state.ervaring.pistes.includes(el.id),
                piste: el.id,
                volgorde: el.volgorde
              }
            )
              .then(response => {
                // console.log(response)
              })
          })
          break
        case 'afgelopenSeizoen':
        case 'eerder':
        case 'aantalWeken':
        case 'laatsteJaar':
          Axios.put(process.env.VUE_APP_API_URL + 'aanmeldinghistorie/' + state.state.id,
            {
              aanmeldingId: state.state.id,
              eerder: state.state.ervaring.eerder,
              afgelopenSeizoen: state.state.ervaring.afgelopenSeizoen,
              wekenErvaring: state.state.ervaring.aantalWeken,
              laatsteJaar: state.state.ervaring.laatsteJaar,
              modified: moment().format('YYYY-MM-DDTHH:mm:ss')
            }
          )
            .then(response => {
              // console.log(response)
            })
      }
    },
    checkForUpload (state) {
      if (state.state.id) {
        state.dispatch('uploadAanmelding', PUT)
        return true
      } else if (
        state.state.client !== null &&
        state.state.activiteit !== null &&
        state.state.cursus !== null
      ) {
        state.dispatch('uploadAanmelding', POST)
        return true
      } else {
        return false
      }
    },
    async uploadAanmelding (state, method) {
      const aanmelding = {
        groupId: state.rootState.familie.info.groupId,
        skisnowboardId: state.state.activiteit ? state.state.activiteit.id : null,
        cursusId: state.state.cursus ? state.state.cursus.id : null,
        materiaalId: state.state.materiaal ? state.state.materiaal.id : null,
        skikaartId: state.state.skikaart ? state.state.skikaart.id : null,
        preftime1Id: !_.isEmpty(state.state.voorkeur[0]) ? state.state.voorkeur[0].dag.dagDagdeelId : null,
        preftime2Id: !_.isEmpty(state.state.voorkeur[1]) ? state.state.voorkeur[1].dag.dagDagdeelId : null,
        signupdate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        remark: state.state.opmerking,
        customerId: state.state.client.customerId,
        actief: state.state.actief
      }
      if (method === POST) {
        Axios.post(process.env.VUE_APP_API_URL + 'aanmelding/', aanmelding)
          .then(response => {
            // console.log(response)
            state.commit('setServerAanmelding', response.data)
            return true
          })
      } else {
        aanmelding.id = state.state.id
        Axios.put(process.env.VUE_APP_API_URL + 'aanmelding/' + state.state.id, aanmelding)
          .then(response => {
            // console.log(response)
            return true
          })
      }
    }
  },
  mutations: {
    setStatus (state, status) {
      state.status = status
    },
    setActief (state, actief) {
      state.actief = actief
    },
    setServerAanmelding (state, aanmelding) {
      state.id = aanmelding.id
    },
    setActiviteit (state, activiteit) {
      state.activiteit = activiteit
      state.materiaal = null
    },
    setCursus (state, cursus) {
      state.cursus = cursus
      state.voorkeur[0] = null
      state.voorkeur[1] = null
    },
    setClient (state, client) {
      state.client = client
    },
    setOpmerking (state, opmerking) {
      state.opmerking = opmerking
    },
    setVoorkeur (state, voorkeur) {
      state.voorkeur[voorkeur.prio] = {
        dag: voorkeur.dag,
        deel: voorkeur.deel
      }
    },
    setErvaring (state, ervaring) {
      state.ervaring[ervaring.key] = ervaring.value
    },
    setMateriaal (state, materiaal) {
      state.materiaal = materiaal
    },
    setSkikaart (state, skikaart) {
      state.skikaart = skikaart
    },
    setAkkoord (state, akkoord) {
      state.akkoord = akkoord
    },
    setAanmelding (state, aanmelding) {
      Object.assign(state, aanmelding)
    },
    resetState (state) {
      // don't lose observers
      Object.assign(state, getDefaultState())
    }
  }
}

export default aanmelding
