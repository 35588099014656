// options voor aanmeldingen
import Axios from 'axios'

const getDefaultState = () => {
  return {
    discipline: [],
    cursussen: [],
    momenten: [],
    activiteiten: [],
    technieken: [],
    pistes: [],
    materiaal: [],
    skikaarten: []
  }
}

const aanmeldOptions = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getActiviteiten ({ commit }) {
      Axios.get(process.env.VUE_APP_API_URL + 'activiteit/')
        .then(response => {
          commit('setActiviteiten', response.data)
        })
    },
    getDisciplines ({ commit }, cursus) {
      Axios.get(process.env.VUE_APP_API_URL + 'Discipline/' + cursus)
        .then(response => {
          commit('setDisciplines', response.data)
        })
    },
    getCursussen ({ commit }) {
      Axios.get(process.env.VUE_APP_API_URL + 'cursus/')
        .then(response => {
          commit('setCursussen', response.data)
        })
    },
    getMomenten ({ commit }, payload) {
      if (payload) {
        Axios.get(process.env.VUE_APP_API_URL + 'cursus/' + payload)
          .then(response => {
            commit('setMomenten', response.data[0].cursusDagDagdeels)
          })
      }
    },
    async getTechnieken ({ commit }, cursus) {
      return Axios.get(process.env.VUE_APP_API_URL + 'techniek1/' + cursus)
        .then(response => {
          commit('setTechnieken', response.data)
        })
    },
    async getPistes ({ commit }, cursus) {
      return Axios.get(process.env.VUE_APP_API_URL + 'piste/')
        .then(response => {
          commit('setPistes', response.data)
        })
    },
    getMateriaal ({ commit }, cursus) {
      Axios.get(process.env.VUE_APP_API_URL + 'materiaal/aanmelding/' + cursus)
        .then(response => {
          commit('setMateriaal', response.data)
        })
    },
    getSkikaarten ({ commit }) {
      Axios.get(process.env.VUE_APP_API_URL + 'skikaart/')
        .then(response => {
          commit('setSkikaarten', response.data)
        })
    },
    reset ({ commit }) {
      commit('resetState')
    }

  },
  mutations: {
    setPistes (state, payload) {
      state.pistes = []
      payload.forEach(pis => {
        state.pistes.push({
          id: pis.piste1,
          titel: pis.piste1,
          volgorde: pis.volgorde
        })
      })
    },
    setTechnieken (state, payload) {
      state.technieken = []
      payload.forEach(tec => {
        state.technieken.push({
          id: tec.id,
          titel: tec.techniek
        })
      })
    },
    setDisciplines (state, payload) {
      state.discipline = []
      payload.forEach(dis => {
        state.discipline.push({
          id: dis.disciplineId,
          titel: dis.disciplineId
        })
      })
    },
    setCursussen (state, payload) {
      state.cursussen = payload
    },
    setMateriaal (state, payload) {
      state.materiaal = payload
    },
    setMomenten (state, payload) {
      state.momenten = payload
    },
    setSkikaarten (state, payload) {
      state.skikaarten = payload
    },
    setActiviteiten (state, payload) {
      state.activiteiten = []
      payload.forEach(dis => {
        state.activiteiten.push({
          id: dis.showGroup2Id,
          titel: dis.omschrijving
        })
      })
    },
    resetState (state) {
      // don't lose observers
      Object.assign(state, getDefaultState())
    }
  }
}

export default aanmeldOptions
