import Axios from 'axios'

const getDefaultState = () => {
  return {
    opmerkingen: []
  }
}

const opmerkingen = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getAll (state, aanmeldingId) {
      Axios.get(process.env.VUE_APP_API_URL + 'aanmeldingopmerking/vooraanmelding/' + aanmeldingId)
        .then(response => {
          state.commit('setOpmerkingen', response.data)
          return response
        })
        .catch(error => {
          return error
        })
    },
    async insert (state, opmerking) {
      return await Axios.post(process.env.VUE_APP_API_URL + 'aanmeldingopmerking', {
        aanmeldingId: opmerking.aanmeldingId,
        actief: true,
        userID: opmerking.userId,
        modified: opmerking.modified,
        opmerking: opmerking.opmerking,
        isWijziging: opmerking.isWijziging ? opmerking.isWijziging : false
      }).then(response => {
        state.commit('addOpmerking', response.data)
        return response
      }
      ).catch(function (error) {
        return error
      })
    }
  },
  mutations: {
    setOpmerkingen (state, opmerkingen) {
      state.opmerkingen = opmerkingen
    },
    addOpmerking (state, opmerking) {
      opmerking.new = true
      state.opmerkingen.unshift(opmerking)
    }
  }
}

export default opmerkingen
