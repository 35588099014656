// familiegegevens
import Axios from 'axios'

const account = {
  namespaced: true,
  getters: {
    isAuthenticated: state => !!state.token,
    StateUser: state => state.user,
    getToken: state => state.token
  },
  state: {
    user: null,
    token: null,
    expiry: null,
    userId: null
  },
  actions: {
    async login ({ commit }, user) {
      return await Axios.post(process.env.VUE_APP_API_URL + 'user/login',
        {
          Email: user.Email,
          Password: user.Password
        },
        {
          headers: {
            'content-type': 'text/json'
          }
        }
      )
        .then(response => {
          commit('setToken', response.data)
          return response
        })
    },
    async forgotPassword ({ commit }, user) {
      return await Axios.post(process.env.VUE_APP_API_URL + 'user/forgotpassword',
        {
          Email: user.Email
        },
        {
          headers: {
            'content-type': 'text/json'
          }
        }
      )
        .then(response => {
          return response
        }).catch(error => {
          return error
        })
    },
    async resetPassword ({ commit }, user) {
      return await Axios.post(process.env.VUE_APP_API_URL + 'user/resetpassword',
        {
          password: user.Password1,
          confirmpassword: user.Password2,
          token: user.Token,
          email: user.Email
        },
        {
          headers: {
            'content-type': 'text/json'
          }
        }
      )
        .then(response => {
          return response
        }).catch(error => {
          return error
        })
    },
    async register ({ commit }, user) {
      return await Axios.post(process.env.VUE_APP_API_URL + 'user/CreateUser',
        {
          Email: user.Email,
          Password: user.Password
        },
        {
          headers: {
            'content-type': 'text/json'
          }
        }
      )
        .then(response => {
          return response
        }).catch(error => {
          return error
        })
    },
    async confirmEmail ({ commit }, user) {
      const params = new URLSearchParams()
      params.append('token', user.token)
      params.append('email', user.email)
      return await Axios.get(process.env.VUE_APP_API_URL + 'user/confirmemail', {
        params: params
      },
      {
        headers: {
          'content-type': 'text/json'
        }
      }
      )
        .then(response => {
          return response
        }).catch(error => {
          return error
        })
    },
    logout ({ commit, dispatch }) {
      commit('setToken', { token: null, user: null, expiry: null })
      dispatch('familie/reset', null, { root: true })
      dispatch('aanmelding/reset', null, { root: true })
      dispatch('options/reset', null, { root: true })
      dispatch('vergaarbak/reset', null, { root: true })
    }
  },
  mutations: {
    setUser (state, username) {
      state.user = username
    },
    setToken (state, data) {
      state.token = data.token
      state.expiry = data.expiry
      state.userId = data.userId
    },
    LogOut (state) {
      state.user = null
    }
  }
}

export default account
