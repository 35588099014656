// familiegegevens
import Axios from 'axios'

const getDefaultState = () => {
  return {
    info: {
      clients: []
    },
    aanmeldingen: []
  }
}

const account = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getFamilie ({ commit }) {
      return await Axios.get(process.env.VUE_APP_API_URL + 'Groups/Group')
        .then(response => {
          commit('setFamilie', response.data)
        })
    },
    getAanmeldingen ({ commit }) {
      Axios.get(process.env.VUE_APP_API_URL + 'aanmelding/AanmeldingVoorGroep/')
        .then(response => {
          commit('setAanmeldingen', response.data)
        })
    },
    async saveFamilie ({ state, commit }) {
      return await Axios.put(process.env.VUE_APP_API_URL + 'Groups/' + state.info.groupId, state.info).then(response => {
        commit('setFamilie', response.data)
        return response
      })
    },
    async saveFamilieLid ({ state, commit }, lid) {
      const client = state.info.clients.filter(client => {
        return client.customerId === lid
      })[0]
      return await Axios.put(process.env.VUE_APP_API_URL + 'Clients/' + lid, client)
    },
    async addClient ({ state, dispatch }, client) {
      client.groupId = state.info.groupId
      return await Axios.post(process.env.VUE_APP_API_URL + 'Clients/', client).then(response => {
        dispatch('getFamilie')
        return response
      })
    },
    setBirthdayOfClient ({ state, commit }, client) {
      commit('setBirthdayOfClient', client)
    },
    reset ({ commit }) {
      commit('resetState')
    }

  },
  mutations: {
    setFamilie (state, payload) {
      var prelastname = payload.prelastname === 'null' || payload.prelastname == null ? '' : payload.prelastname
      payload.achternaamvol = (prelastname + ' ' + payload.lastname).trim()
      state.info = payload
    },
    setAanmeldingen (state, payload) {
      state.aanmeldingen = payload.sort((a, b) => Date.parse(b.signupdate) - Date.parse(a.signupdate))
    },
    resetState (state) {
      // don't lose observers
      Object.assign(state, getDefaultState())
    },
    setBirthdayOfClient (state, client) {
      const index = state.info.clients.findIndex(c => c.customerId === client.customerId)
      state.info.clients[index].birthdate = client.birthdate
    }
  }
}

export default account
