export default [
  {
    cursusId: 3,
    dagDagdeelId: 1,
    dagDagdeel: {
      dagId: 1,
      dagdeelId: 1,
      id: 1,
      dag: {
        prechoiceId: 1,
        description: 'zondag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 2,
    dagDagdeel: {
      dagId: 1,
      dagdeelId: 2,
      id: 2,
      dag: {
        prechoiceId: 1,
        description: 'zondag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 3,
    dagDagdeel: {
      dagId: 1,
      dagdeelId: 3,
      id: 3,
      dag: {
        prechoiceId: 1,
        description: 'zondag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 4,
    dagDagdeel: {
      dagId: 2,
      dagdeelId: 1,
      id: 4,
      dag: {
        prechoiceId: 2,
        description: 'maandag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 5,
    dagDagdeel: {
      dagId: 2,
      dagdeelId: 2,
      id: 5,
      dag: {
        prechoiceId: 2,
        description: 'maandag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 6,
    dagDagdeel: {
      dagId: 2,
      dagdeelId: 3,
      id: 6,
      dag: {
        prechoiceId: 2,
        description: 'maandag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 7,
    dagDagdeel: {
      dagId: 3,
      dagdeelId: 1,
      id: 7,
      dag: {
        prechoiceId: 3,
        description: 'dinsdag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 8,
    dagDagdeel: {
      dagId: 3,
      dagdeelId: 2,
      id: 8,
      dag: {
        prechoiceId: 3,
        description: 'dinsdag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 9,
    dagDagdeel: {
      dagId: 3,
      dagdeelId: 3,
      id: 9,
      dag: {
        prechoiceId: 3,
        description: 'dinsdag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 10,
    dagDagdeel: {
      dagId: 4,
      dagdeelId: 1,
      id: 10,
      dag: {
        prechoiceId: 4,
        description: 'woensdag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 11,
    dagDagdeel: {
      dagId: 4,
      dagdeelId: 2,
      id: 11,
      dag: {
        prechoiceId: 4,
        description: 'woensdag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 12,
    dagDagdeel: {
      dagId: 4,
      dagdeelId: 3,
      id: 12,
      dag: {
        prechoiceId: 4,
        description: 'woensdag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 13,
    dagDagdeel: {
      dagId: 5,
      dagdeelId: 1,
      id: 13,
      dag: {
        prechoiceId: 5,
        description: 'donderdag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 14,
    dagDagdeel: {
      dagId: 5,
      dagdeelId: 2,
      id: 14,
      dag: {
        prechoiceId: 5,
        description: 'donderdag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 15,
    dagDagdeel: {
      dagId: 5,
      dagdeelId: 3,
      id: 15,
      dag: {
        prechoiceId: 5,
        description: 'donderdag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 16,
    dagDagdeel: {
      dagId: 6,
      dagdeelId: 1,
      id: 16,
      dag: {
        prechoiceId: 6,
        description: 'vrijdag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 17,
    dagDagdeel: {
      dagId: 6,
      dagdeelId: 2,
      id: 17,
      dag: {
        prechoiceId: 6,
        description: 'vrijdag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 18,
    dagDagdeel: {
      dagId: 6,
      dagdeelId: 3,
      id: 18,
      dag: {
        prechoiceId: 6,
        description: 'vrijdag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 19,
    dagDagdeel: {
      dagId: 7,
      dagdeelId: 1,
      id: 19,
      dag: {
        prechoiceId: 7,
        description: 'zaterdag'
      },
      dagdeel: {
        id: 1,
        periode: 'ochtend'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 20,
    dagDagdeel: {
      dagId: 7,
      dagdeelId: 2,
      id: 20,
      dag: {
        prechoiceId: 7,
        description: 'zaterdag'
      },
      dagdeel: {
        id: 2,
        periode: 'middag'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 21,
    dagDagdeel: {
      dagId: 7,
      dagdeelId: 3,
      id: 21,
      dag: {
        prechoiceId: 7,
        description: 'zaterdag'
      },
      dagdeel: {
        id: 3,
        periode: 'avond'
      }
    }
  },
  {
    cursusId: 3,
    dagDagdeelId: 22,
    dagDagdeel: {
      dagId: 8,
      dagdeelId: 4,
      id: 22,
      dag: {
        prechoiceId: 8,
        description: 'geen'
      },
      dagdeel: {
        id: 4,
        periode: 'voorkeur'
      }
    }
  }
]
