const authRouter = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Inloggen'
    },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/registreren',
    name: 'register',
    meta: {
      title: 'Nieuw account aanmaken'
    },
    component: () => import('../views/auth/Register.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'wachtwoord-vergeten',
    meta: {
      title: 'Wachtwoord vergeten'
    },
    component: () => import('../views/auth/PasswordForgotten.vue')
  },
  {
    path: '/aanmelden/resetpassword',
    name: 'wachtwoord-wijzigen',
    meta: {
      title: 'Wachtwoord wijzigen'
    },
    component: () => import('../views/auth/PasswordReset.vue')
  },
  {
    path: '/aanmelden/confirmemail',
    name: 'user-confirmemail',
    meta: {
      title: 'Email bevestigd'
    },
    component: () => import('../views/auth/EmailConfirm.vue')
  }
]

export default authRouter
