const beheerRouter = [
  {
    path: 'vergaarbak',
    name: 'Vergaarbak',
    meta: {
      title: 'Vergaarbak'
    },
    component: () => import('../views/beheer/pages/Vergaarbak.vue')
  },
  {
    path: 'historiebak',
    name: 'Historiebak',
    meta: {
      title: 'Historiebak'
    },
    component: () => import('../views/beheer/pages/Historiebak.vue')
  },
  {
    path: 'aanmelding/:id',
    name: 'Aanmelding',
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10)
      if (Number.isNaN(id)) {
        return 0
      }
      return { id }
    },
    meta: {
      title: 'Aanmelding'
    },
    component: () => import('../views/beheer/pages/Aanmelding.vue')
  }
]

export default beheerRouter
