const aanmeldingRouter = [
  {
    path: 'persoon',
    name: 'StapPersoon',
    meta: {
      step: 0,
      shortTitle: 'Gezinslid',
      title: 'Aanmelden - Stap 1: Gezinslid'
    },
    component: () => import('../views/aanmelding/onderdelen/Persoon.vue')
  },
  {
    path: 'activiteit',
    name: 'StapActiviteit',
    meta: {
      step: 1,
      shortTitle: 'Discipline',
      title: 'Aanmelden - Stap 2: Discipline'
    },
    component: () => import('../views/aanmelding/onderdelen/Activiteit.vue')
  },
  {
    path: 'cursus',
    name: 'StapCursus',
    meta: {
      step: 2,
      shortTitle: 'Cursus',
      title: 'Aanmelden - Stap 3: Cursus'
    },
    component: () => import('../views/aanmelding/onderdelen/Cursus.vue')
  },
  {
    path: 'voorkeursmomenten',
    name: 'StapMomenten',
    meta: {
      step: 3,
      shortTitle: 'Voorkeursmomenten',
      title: 'Aanmelden - Stap 4: Voorkeursmomenten'
    },
    component: () => import('../views/aanmelding/onderdelen/Momenten.vue')
  },
  {
    path: 'ervaring',
    name: 'StapErvaring',
    meta: {
      step: 4,
      shortTitle: 'Ervaring',
      title: 'Aanmelden - Stap 5: Ervaring'
    },
    component: () => import('../views/aanmelding/onderdelen/Ervaring.vue')
  },
  {
    path: 'materiaal',
    name: 'StapMateriaal',
    meta: {
      step: 5,
      shortTitle: 'Materiaal',
      title: 'Aanmelden - Stap 6: Materiaal huren'
    },
    component: () => import('../views/aanmelding/onderdelen/Materiaal.vue')
  },
  {
    path: 'skikaart',
    name: 'StapSkikaart',
    meta: {
      step: 6,
      shortTitle: 'Skikaart',
      title: 'Aanmelden - Stap 7: Skikaart'
    },
    component: () => import('../views/aanmelding/onderdelen/Skikaart.vue')
  },
  {
    path: 'aanvullingen',
    name: 'StapOpmerkingen',
    meta: {
      step: 7,
      shortTitle: 'Aanvullingen',
      title: 'Aanmelden - Stap 8: Aanvullingen'
    },
    component: () => import('../views/aanmelding/onderdelen/Opmerkingen.vue')
  },
  {
    path: 'controleer-aanmelding',
    name: 'StapControle',
    meta: {
      step: 8,
      shortTitle: 'Controle',
      title: 'Aanmelden - Stap 9: Controle en opmerkingen'
    },
    component: () => import('../views/aanmelding/onderdelen/Controle.vue')
  }
]

export default aanmeldingRouter
